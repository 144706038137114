@import "../../../../styles/index";

.ninth_page {
  padding: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 40px;

  &__semaforo {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
    gap: 15px;
    width: 100%;
    background-color: $white1;
    border-radius: 30px;
    padding: 25px 20px;

    &__lights {
      grid-column: 1/3;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      div {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }

      &__red {
        background-color: rgb(205, 98, 98);
      }

      &__yellow {
        background-color: rgb(245, 175, 69);
      }

      &__green {
        background-color: rgb(100, 194, 98);
      }

      .inactive {
        background-color: $white1;
      }
    }

    &__whysemaforo {
      grid-column: 3/11;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      gap: 5px;

      &__text {
        text-align: start !important;
      }

      &__info {
        text-align: start !important;
        font-weight: 600 !important;
        font-size: 14px !important;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
