@import "../../../../styles/index.scss";

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 0 30px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bakeca-bg;

  @media (max-width: 1100px) {
    height: 90px;
    padding: 0 100px 0 30px;
  }

  @media (max-width: 460px) {
    padding: 0 90px 0 20px;
  }

  @media (max-width: 460px) {
    padding: 0 80px 0 20px;
  }

  &_logo {
    width: 220px;
    height: 65px;

    img {
      width: 100%;
      height: 100%;
    }

    // @media (max-width: 900px) {
    //   display: none;
    // }
  }

  // &_logo_small {
  //   width: 60px;

  //   img {
  //     width: 100%;
  //   }

  //   @media (min-width: 901px) {
  //     display: none;
  //   }
  // }

  &_links {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media (max-width: 1100px) {
      display: none;
    }

    li {
      // a {
      font-size: 13px;
      cursor: pointer;
      color: $neutral-400;
      transition: color 0.3s ease-in-out 0s;
      text-decoration: none;
      display: block;

      &:hover,
      &:focus {
        color: $primary-bakeca-dark;
      }

      &:focus {
        outline: none;
        // outline: 1px solid $primary-bakeca-dark;
        // padding: 5px;
        // border-radius: 2px;
      }
      //}
    }
  }

  &_ctas {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &_login,
    &_buy_report {
      @media (max-width: 1100px) {
        display: none;
      }
    }

    .dashboard_button {
      svg {
        width: auto !important;
        height: auto !important;
      }
    }
  }
}

.logo_shadow {
  transition: box-shadow 0.3s ease-in;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}
