@import "../../../../../styles/index.scss";

.stripe {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.stripe_element {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  color: $bakeca-black;
  border: 1px solid $primary-bakeca-dark;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  font-family: $font-raleway;
}

.stripe_element {
  &:focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
}

.stripe_element--invalid {
  border-color: $error;
}

.stripe_element--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripe_disabled {
  background-color: #aeaeb2;
}
