@import "../../../styles/index.scss";

.account {
  background-color: $white;
  // height: 200px;
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;

  @media (max-width: 600px) {
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    &__title {
      font-weight: 600;
      font-size: 15px;
    }

    &__group {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
    }

    hr {
      width: 100%;
      margin: 20px 0 20px;
      height: 1px;
      background-color: $neutral-500;
    }

    &__modify {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &__form {
        width: 100%;

        form {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        &_btns {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 10px;

          .save_edit {
            &:hover,
            &:focus {
              box-shadow: 0 0.5em 0.5em -0.4em $accent-800 !important;
            }
          }

          .undo_edit {
            &:hover,
            &:focus {
              box-shadow: 0 0.5em 0.5em -0.4em $neutral-500 !important;
            }
          }
        }
      }
    }
  }

  .text {
    font-size: 15px;
  }

  .edit {
    &:hover,
    &:focus {
      box-shadow: 0 0.5em 0.5em -0.4em $bakeca-orange !important;
    }
  }

  .delete_acct {
    &:hover,
    &:focus {
      box-shadow: 0 0.5em 0.5em -0.4em $error !important;
    }
  }
}
