@import "../../../styles/index.scss";

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  @media (min-height: 1001px) {
    gap: 40px;
  }

  button {
    margin-top: 20px;
  }

  .submit_btn {
    &:hover,
    &:focus {
      box-shadow: 0 0.5em 0.5em -0.4em $primary !important;
    }
  }
}
