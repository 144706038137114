@import "../../../styles/index.scss";

.auth_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  backdrop-filter: blur(35px);
  background: rgba(3, 67, 73, 0.25);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.show {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.2s ease-in-out;
}
