@import "../../../../../../styles/index.scss";

.population_density {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  position: relative;
}

.text {
  position: absolute;
  top: 52%;

  .label {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .source {
    font-size: 12px;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.7px;
    color: $bakeca-black;

    span {
      font-size: 14px;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;
      font-weight: 700;
    }
  }
}
