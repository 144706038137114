@import "../../styles/index.scss";

.dashboard {
  // background-color: $primary-100;

  &__wrapper {
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    gap: 80px;

    @media (min-width: 1600px) {
      width: 80%;
      margin: 0 auto;
      padding: 40px 0;
    }

    @media (min-width: 1700px) {
      width: 75%;
    }

    @media (max-width: 650px) {
      padding: 40px 60px;
    }

    @media (max-width: 520px) {
      padding: 40px;
    }

    @media (max-width: 410px) {
      padding: 40px 30px;
    }
  }

  &__header {
    text-align: left;

    @media (max-width: 560px) {
      display: none;
    }
  }

  .dashboard__content {
    display: flex;
    flex-direction: column;
    gap: 80px;

    &__section_header {
      // text-transform: uppercase;
      font-family: $font-raleway;
      font-weight: 500;
      color: $neutral-primary;
      font-size: 26px;
      text-align: left;
      margin-bottom: 30px;
    }

    &__complete__slider {
      @media (min-width: 981px) {
        display: none;
      }
    }

    &__subsection {
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .dashboard__content__accountandcredits {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      gap: 80px;
    }
  }
}

.delete {
  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em $error !important;
  }
}
