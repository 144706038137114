$white: #fff7fa;
$white1: #f4f7f8;
$gray: #ededed80;
$gray1: #9a9a9a;
$gray2: #9d9d9d;
$dark-gray: #51676c;
$light-gray: #ededed;
$green: #067c89;
$bianco: #ecf7f8;
$bianco-light: #ebfcfe;
$primary: #078ea6;
$primary-dark: #0f687c;
$sea-green: #11606d;
$pale-green: #607b7d;
$sea-green-middle: #00b1c4;
$sea-green-light: #bfeef3;
$sea-text: #056f7a;
$download-div: #4dafc1;
$primary-light: #08a6b6;
// $primary-gradient: linear-gradient(83.15deg, #0a7e98 0%, #0ca1b1 100%);
// $secondary-gradient: linear-gradient(87.04deg, #5e239d 0%, #8129bc 100%);
$violet-gradient: linear-gradient(
  87.04deg,
  rgba(94, 35, 157, 0.5) 0%,
  rgba(129, 41, 188, 0.5) 100%
);
$violet-light-gradient: linear-gradient(
  87.04deg,
  rgba(94, 35, 157, 0.1) 0%,
  rgba(129, 41, 188, 0.1) 100%
);
$black: #333333;
$nero: #034349;
$red: #fd7072;
$rosso: #fc440f;
$giallo: #cfd11a;
$blue: #151ee7;
$violet: #5e239d;
$footerLink: #0aa5b5;
$paymentBox: #d8e9eb;
$mappaSection: #00a5b5;
$mappaSectionFont: #056f81;
$mappaSectionDetails: #3da2b1;

$primary-gradient: linear-gradient(
  180deg,
  #0a7e98 0%,
  rgba(12, 161, 177, 0.5) 100%
);
$primary-gradient-opposite: linear-gradient(
  180deg,
  rgba(12, 161, 177, 0.5) 0%,
  #0a7e98 100%
);
$primary-gradient-new: linear-gradient(
  90deg,
  rgba(3, 67, 73, 1) 0%,
  rgba(7, 142, 166, 1) 100%
);
// linear-gradient(
//   90deg,
//   rgba(10, 126, 152, 1) 0%,
//   rgba(12, 161, 177, 0.5) 60%
// );
$primary: #078ea6;
$primary-500: #00a5b5;
$primary-400: #00b1c4;
$primary-300: #99d9e2;
$primary-200: #9ee3e8;
$primary-100: #ecf7f8;

$neutral-primary: #034349;
$neutral-900: #20585e;
$neutral-800: #326b70;
$neutral-700: #53979d;
$neutral-400: #616161;
$neutral-500: #8c8c8c;
$neutral-300: #f0f0f0;
$neutral-200: #f9f9fa;
$white: #fff;

$accent-primary: #0b2c3a;
$accent-900: #1b3f4e;
$accent-800: #24707d;

$light-bg-gradient: linear-gradient(300deg, #11606d 0%, #078ea6 100%);
$dark-bg-gradient: linear-gradient(180deg, #078ea6 0%, #16a085 100%);

$houseplus-gradient: linear-gradient(90deg, #1a3f4e, #20bfff);
$houseplus-gradient-secondary: linear-gradient(
  315deg,
  #68a9b4 7.57%,
  #72f7d0 28.65%,
  #83ffd8 38%,
  #067b87 101%
);

$error: #b10000;
$success: #3b7a36;
$warning: #002987;

$downloadSection: #00a5b5;
$loaderWhite: #ecf7f8;

$bakeca-bg: #fcfff7;
$primary-bakeca: #80ba27;
$primary-bakeca-dark: #65a30d;
$bakeca-black: #0f172a;
$bakeca-purple: #64748b;
$bakeca-orange: #f97316;
$bakeca-gray: #a6bea229;
$bakeca-gradient: linear-gradient(90deg, #0f172a, #80ba27);
$primary-bakeca-gradient: linear-gradient(
  180deg,
  #80ba27 0%,
  rgba(128, 186, 39, 0.6) 100%
);

// $primary-bakeca-gradient: linear-gradient(
//   180deg,
//   #80ba27 0%,
//   rgba(12, 161, 177, 0.5) 100%
// );
