@import "../../../../styles/index.scss";

.primary__btn {
  border: none;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  gap: 5px;
  background-color: $primary-bakeca;
  border-radius: 4px;
  height: 45px;
  width: 180px;

  transition: 0.25s;

  &:hover {
    box-shadow: 0 0.5em 0.5em -0.4em $primary-bakeca;
    transform: translateY(-0.15em);
  }

  &:focus {
    transform: translateY(-0.15em);
  }

  &:active {
    transform: translateY(0.15em);
    box-shadow: none;
  }

  &:disabled {
    pointer-events: none;
    // cursor: not-allowed;
  }

  span {
    font-size: 13px;
    font-weight: 600;
    color: $white;
  }

  .left-icon {
    height: 100%;
    width: 15px;
  }

  .right-icon {
    height: 100%;
    width: 15px;
  }

  &:disabled {
    opacity: 0.5;
  }
}
