@import "../../../../styles/index.scss";

.swiper-wrapper {
  align-items: center !important;
  flex-direction: row !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  background: $white;
  opacity: 0.5;
}

.swiper-pagination-horizontal {
  bottom: -3px !important;

  @media (max-width: 640px) {
    bottom: -10px !important;
    height: 30px;
  }
}

.mobile__slider {
  .swiper-pagination-bullet {
    background: $primary;
    opacity: 0.5;
  }

  .swiper-pagination-horizontal {
    @media (max-width: 1000px) {
      top: 0 !important;
      bottom: auto !important;
    }
  }
}
