@import "./modules/colors.scss";
@import "./modules/variables.scss";
@import "./modules/reset.scss";

* {
  box-sizing: border-box;
  font-family: $font-raleway;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: 46px;
  font-weight: 600;
  line-height: 1.2;

  span {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-family: inherit;
  }
}

h2,
.h2 {
  font-size: 28px;
  letter-spacing: 1.45px;
}

h3,
.h3 {
  font-size: 22px;
  line-height: 115%; /* 29.9px */
}

h4,
.h4 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.45px;
}

h5,
.h5 {
  font-size: 18px;
}

b,
strong {
  font-weight: 600;
  font-size: inherit;
  font-family: inherit;
  color: $bakeca-black;
}

button {
  cursor: pointer;
  outline: none;
}

p {
  color: $neutral-400;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.36px;
}

@media print {
  @page {
    margin: 0;
    size: A4 portrait;
  }
  .page-break {
    page-break-after: always;
  }
}
@media print {
  html,
  body,
  .container {
    height: 100% !important;
    width: 100% !important;
    overflow: visible;
    display: block;
  }
}
