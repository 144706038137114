@import "../../../styles/index.scss";

.agency_cta_container {
  width: 100%;
  padding-top: 60px;
  background-color: $white;
  padding: 60px 80px 0px 80px;

  @media (max-width: 900px) {
    padding: 60px 0 0 0;
  }
}

.agency_cta {
  padding: 40px 68px;
  gap: 60px;
  max-width: 1085px;
  margin: 0 auto;
  background: $primary-gradient-new;
  border-radius: 48px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  position: relative;

  @media (max-width: 900px) {
    border-radius: 0px;
  }

  @media (max-width: 750px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 80px;
  }

  @media (max-width: 540px) {
    padding: 60px;
  }

  @media (max-width: 500px) {
    padding: 80px 40px;
  }

  &__logo_small {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 140px;

    @media (min-width: 751px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__logo {
    width: 100%;

    @media (max-width: 1100px) {
      width: 240px;
    }

    @media (max-width: 750px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 26px;

    @media (max-width: 450px) {
      align-items: center;
    }

    * {
      @media (max-width: 450px) {
        text-align: center !important;
      }
    }

    &__sub {
      font-weight: 400;
      font-size: 16px;
      text-align: start;
      color: #ffffff;
    }

    h1 {
      color: #ffffff;
      text-align: start;
      font-weight: 500;
    }
  }

  button {
    &:hover {
      box-shadow: 0 0.5em 0.5em -0.4em #ffffff !important;
    }
  }

  a {
    text-decoration: none;
  }
}
