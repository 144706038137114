@import "../../../../styles/index.scss";

.contact_us_modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    @media (max-width: 1100px) {
      display: none;
    }

    img {
      width: 80%;
      height: 60vh;
    }
  }

  .form_wrapper {
    background: $accent-primary;
    flex: 1;
    height: 100%;
    width: 100%;
    width: 50%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0px;
    padding: 40px 40px 20px;

    @media (max-width: 1100px) {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    @media (max-width: 900px) and (max-height: 480px) {
      overflow: auto;
      max-height: 420px;
    }

    @media (max-width: 900px) {
      max-width: 100%;
    }

    .form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      @media (min-height: 800px) and (min-width: 901px) {
        min-height: 50vh;
      }

      @media (min-width: 901px) {
        min-height: 71vh;
      }

      &_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;

        h4 {
          color: $neutral-200;
          margin-bottom: 10px;

          @media (max-width: 900px) {
            display: none;
          }
        }

        &_fields {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 10px;
          row-gap: 30px;
          width: 100%;

          @media (max-width: 550px) {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        &_privacy {
          p {
            font-size: 12px;
            color: $neutral-200;
            margin-bottom: 20px;
          }

          a {
            font-size: 12px;
            color: $neutral-200;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .close_btn {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
    color: $white;
    cursor: pointer;

    @media (max-width: 600px) {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .submit_btn {
    &:hover,
    &:focus {
      box-shadow: 0 0.5em 0.5em -0.4em $primary !important;
    }
  }
}
