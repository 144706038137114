@import "../../../../../styles/index.scss";

.total_info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  background-color: $bakeca-gray;
  border-radius: 8px;
  padding: 20px;

  .subtitle {
    font-weight: 600;
    letter-spacing: 0.3px;
    font-size: 18px;
    text-align: start;
    width: 100%;
    margin-bottom: 5px;
  }

  &__price_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .text {
      color: $bakeca-black;
      letter-spacing: 0.3px;
      font-size: 14px;
    }

    .number {
      letter-spacing: 0.3px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
