@import "../../../styles/index.scss";

.dashboard__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 30px;

  &__logo {
    width: 220px;
    height: 65px;
    padding-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &__link {
      @media (max-width: 650px) {
        display: none;
      }
    }

    &__link_mobile {
      position: relative;

      @media (min-width: 651px) {
        display: none;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}
