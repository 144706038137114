@import "../../../../styles/index.scss";

.cards-mobile {
  .swiper {
    width: 200px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .swiper-slide-shadow {
    background: none;
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    background: $primary-bakeca-dark;
    opacity: 0.5;
  }

  .swiper-pagination-horizontal {
    position: relative;
    margin-top: 6px;
  }
}

.cards-tablet {
  .swiper-slide-shadow {
    background: none;
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    background: $primary-bakeca-dark;
    opacity: 0.5;
  }

  .swiper-pagination-horizontal {
    position: relative;
    margin-top: 6px;
  }
}
