@import "../../../../styles/index";

.second_page_additional {
  padding: 40px 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: calc(100% - 75px);
  gap: 32px;

  .main_info {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;

    &__info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      width: 90%;
      column-gap: 60px;
      margin: 0 auto;

      .info {
        display: flex;
        align-items: center;
        gap: 15px;

        span {
          font-weight: 700 !important;
          font-size: 14px !important;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.36px;
          text-align: start;

          sup {
            font-size: 8px;
          }
        }

        .item_circle {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: rgba(7, 142, 166, 0.5);
        }
      }
    }
  }

  .ad_description {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;

    .description {
      text-align: justify;
    }
  }

  .photos {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin: 0 auto;

      img {
        width: 224px;
        height: 175px;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }

  .location {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;

    img {
      width: 712px;
      height: 250px;
      border-radius: 10px;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}
