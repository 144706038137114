@import "../../../styles/index.scss";

.field__wrapper {
  position: relative;
  width: 100%;

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    text-align: start;

    .field__label {
      display: block;
      font-weight: 600;
      font-size: 13px;
      font-family: $font-raleway;
    }

    .label_info {
      font-family: $font-raleway;
      letter-spacing: 0.48px;
      font-weight: 500;
      color: rgba(97, 97, 97, 0.74);
      font-size: 13px;
    }

    .forgot_password {
      font-family: $font-raleway;
      letter-spacing: 0.3px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      color: $bakeca-black;
      text-decoration: underline;
      transition: color 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        color: $primary-bakeca-dark;
      }
    }
  }

  .input-group {
    width: 100%;
    color: $bakeca-black;
    // padding: 10px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    border-radius: 5px;
    border: 1px solid $primary-bakeca-dark;
    background: #fff;
    // padding-right: 12px;
    height: 50px;

    .field__input {
      width: 100%;
      height: 100%;
      border: none;
      padding: 10px;
      padding-right: 12px;
      color: $bakeca-black;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.48px;
      font-family: $font-raleway;
      transition: background-color 0.25s;

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: #aeaeb2;
        font-size: 14px;
      }

      &:disabled {
        background-color: #aeaeb2;
        // background-color: #ebebe4;
      }
    }
  }

  .error-field {
    color: $error;
    border: 1px solid $error;
  }

  .error {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 5px;
    color: $error;
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
    font-family: $font-raleway;
  }
}

input[type="password"i] {
  color: $bakeca-black !important;
}

input[type="password"i].error-field {
  color: $error !important;
}

.pwd_icon {
  cursor: pointer;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 11px;
}
