nav {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  @media (max-width: 1100px) {
    height: 90px;
  }
}
