@import "../../../../styles/index";

.sixth_page {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__charts_grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 25px;

    .general__card {
      background: $white1;
      // border: 2px solid red;
      border-radius: 30px;
      height: auto;
    }

    .pop__change {
      grid-column: 1/7;
    }

    .family {
      grid-column: 1/3;
    }

    .avg_price {
      grid-column: 3/5;
    }

    .ads_amount {
      grid-column: 5/7;
    }

    .population__density__area {
      grid-column: 1/4;
      grid-row: 3/4;
    }

    .avg_earnings {
      grid-row: 4/5;
      grid-column: 1/4;
    }

    .gender__distribution__area {
      grid-column: 4/7;
      grid-row: 3/5;
    }
  }
}
