@import "../../../../../styles/index";

.similar_properties {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__subtitle {
    font-size: 13px;
    letter-spacing: 0.36px;
    text-align: justify;
    margin-bottom: 5px;
  }

  &__table {
    // margin-bottom: 30px;
    // border: 1px solid #daebee;

    table {
      width: 100%;
      background-color: #fafafa;
      border-spacing: 0px !important;
      min-width: 650px;

      thead {
        // background-color: $sea-green-middle;
        color: $white;

        th {
          background-color: $sea-green-middle;
          font-size: 12px;
          font-weight: 500;
          padding: 8.5px 10px;
        }

        th:not(:last-child) {
          border-right: 1px solid #daebee;
        }
      }

      tbody {
        tr {
          text-align: center;
          // height: 24px;

          td {
            font-size: 11px;
            border-bottom: 1px solid #daebee;
            padding: 5px 15px;

            div {
              font-size: 11px;
            }
          }

          .type_column {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .price_column {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: inherit;
            font-weight: 600;

            img {
              width: 9px;
              display: block;
            }
          }
        }
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-left: 15px;

    &__text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 500;

      img {
        width: 9px;
        display: inline-block;
      }
    }
  }

  &__nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.36px;
    margin-top: 10px;
  }
}
