.horizontal {
  tspan {
    font-size: 10px;
  }

  text {
    font-size: 13px !important;
  }

  .recharts-legend-wrapper {
    top: 7px !important;
  }
}
