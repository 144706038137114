@import "../../../styles/index.scss";

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;

  thead {
    tr {
      th {
        font-weight: 600;
        color: $neutral-400;
        font-size: 12px;
        padding-bottom: 5px;
      }
    }
  }

  tbody {
    tr {
      background-color: $white;
      margin-bottom: 10px;

      td {
        font-weight: 600;
        color: $neutral-primary;
        font-size: 14px;
        padding: 10px 15px;
        box-shadow: 0px 1.95px 0px rgba(0, 0, 0, 0.15);
      }

      .far_left {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }

      .far_right {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 0px;
      }
    }
  }
}
