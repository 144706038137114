@import "../../../../../styles/index.scss";

.card {
  background-color: $white;
  height: 280px;
  width: 200px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  padding: 40px 0px;
  border: 1px solid $bakeca-black;
  position: relative;

  @media (min-width: 601px) {
    border: none;
  }

  @media (min-width: 1251px) {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 15px -8px;
  }

  @media (min-width: 601px) and (max-width: 1250px) {
    /* Your styles for the element */
    margin: 0 auto;
  }

  &_title {
    // text-transform: uppercase;
    // font-family: $font-raleway;
    font-weight: 600;
    color: $bakeca-black;
  }

  &_img {
    width: 70%;
    position: relative;

    &_blur {
      left: -3px;
      width: 103%;
      height: 121%;
      max-height: 160px;
      position: absolute;
      top: -6px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      backdrop-filter: blur(5px);
      // filter: blur(10px);
      z-index: 10;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &_btn {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
  }

  &__graphic {
    width: 100%;
  }
}

.isBuy {
  background-color: $primary-bakeca;
  cursor: pointer;
  padding: 40px 20px;
  gap: 20px;

  transition: transform 0.25s;

  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em $primary-bakeca !important;
    transform: translateY(-0.3em) !important;
  }

  &:active {
    // border-color: $primary-bakeca !important;
    transform: translateY(0.3em) !important;
  }

  .card_buy__title {
    font-weight: 500;
    color: #ffffff;
  }
}
