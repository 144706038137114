@import "../../../../styles/index.scss";

.data_preview {
  position: relative;
  background-color: $bakeca-bg;
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-out;
  padding: 20px;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  border-radius: 8px;
  max-height: 98vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 820px) {
    max-width: 96%;
  }

  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &::-webkit-scrollbar {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .close {
    @media (max-width: 670px) {
      top: 34px;
      right: 34px;
    }

    @media (max-width: 500px) {
      top: 34px;
      right: 34px;
    }
  }

  &__left {
    padding: 20px;
    background-color: $bakeca-gray;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
    // justify-content: center;
    position: relative;
    // overflow-x: hidden;

    &__header {
      line-height: 1.4;
      font-weight: 500;

      @media (max-width: 670px) {
        padding-right: 40px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      // list-style-position: inside;
      list-style-type: none;
      z-index: 10;

      @media (max-width: 670px) {
        display: grid;
        grid-template-columns: 1fr;
      }

      li {
        font-weight: 700;
        letter-spacing: 1.35px;
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;

        img {
          width: 18px;
        }
      }
    }

    &__btn {
      margin-top: 20px;
      width: 280px;
      z-index: 10;

      @media (min-width: 601px) {
        display: none;
      }

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    &__img {
      position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      bottom: 0px;
      right: 0px;
      width: 300px;
      max-height: 350px;
      opacity: 0.3;
      overflow: hidden;
      height: 100%;

      @media (min-width: 671px) {
        display: none;
      }

      img {
        position: absolute;
        bottom: -35px;
        right: -61px;
        width: 100%;
      }
    }
  }

  &__right {
    padding: 0 0 0 20px;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @media (max-width: 670px) {
      display: none;
    }

    &__img {
      width: 300px;
    }
  }
}
