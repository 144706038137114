@import "../../styles/index";

.book {
  margin: 0 auto;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: $neutral-200;

  &::-webkit-scrollbar {
    display: none;
  }

  .page {
    // height: 296.35mm;
    height: 297mm;
    width: 210mm;
    overflow: hidden;
    position: relative;

    &__logo {
      background-color: $downloadSection;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 35px;

      img {
        width: 90px;
      }
    }

    &__title {
      letter-spacing: 2px;
      font-size: 26px;
      text-align: start;
    }

    &__section_title {
      text-transform: uppercase;
      font-family: $font-raleway;
      font-weight: 600;
      color: $neutral-primary;
      text-align: start;
    }

    &__section_subtitle {
      text-transform: uppercase;
      font-family: $font-raleway;
      font-weight: 600;
      color: $neutral-primary;
      text-align: start;
      font-size: 13px;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.36px;
      text-align: start;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding: 0px 30px 10px;

      .footer__date {
        font-size: 12px;

        b {
          font-size: inherit;
        }
      }

      .footer__number {
        font-size: 12px;
      }
    }

    .image__wrapper {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      text-align: center;
      width: 40%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: 83%;
      font-size: 18px;
      line-height: 1;
      font-weight: 400;
    }

    .page__number {
      font-size: 25px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 95%;
    }

    .page__nameAgenzy {
      font-size: 12px;
      position: absolute;
      left: 84px;
      transform: translateX(-50%);
      top: 96%;
    }

    .page__date {
      font-size: 12px;
      position: absolute;
      left: 90%;
      transform: translateX(-50%);
      top: 95%;
    }
  }
}

.white {
  color: $white;
}

.hide {
  display: none;
}
