@import "../../../../styles/index.scss";

.banner {
  width: 100%;
  padding: 80px 80px 60px;
  max-width: 1085px;
  margin: 0 auto;

  @media (min-width: 1150px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 540px) {
    padding: 60px;
  }

  @media (max-width: 500px) {
    padding: 80px 40px;
  }

  &_header {
    margin-bottom: 40px;
    // letter-spacing: 1.15px;
    font-size: 18px;
    color: $neutral-primary;
    font-weight: 600;

    // @media (max-width: 1200px) {
    //   margin-bottom: 40px;
    // }
  }

  &_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    @media (max-width: 1200px) {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      justify-items: center;
      row-gap: 40px;

      &_card:nth-child(1) {
        grid-column: 2/4;
      }

      &_card:nth-child(2) {
        grid-column: 5/7;
      }

      &_card:nth-child(3) {
        grid-column: 8/10;
      }

      &_card:nth-child(4) {
        grid-column: 3/5;
      }

      &_card:nth-child(5) {
        grid-column: 7/9;
      }
    }

    @media (max-width: 1000px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      gap: 40px;
      row-gap: 40px;
    }

    &_card {
      width: 180px;

      img {
        width: 100%;
      }
    }
  }
}
