@import "../../../../styles/index.scss";

.complete__card {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  // gap: 20px;
  width: 250px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 395.5px;

  @media (max-width: 430px) {
    width: 100%;
    box-shadow: none;
  }

  &__image {
    height: 126.44px;
    object-fit: cover;
    border-radius: 10px;
  }

  img {
    width: 100%;
  }

  &__id {
    line-height: 1;
    text-align: start;

    span {
      font-weight: 600;
      font-size: 15px;
    }

    a {
      color: $neutral-400;
      font-size: 14px;
      transition: color 0.3s ease-in-out 0s;
      display: inline-block;
      font-weight: 600;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  &__address {
    line-height: 1;
    font-size: 18px;
    font-weight: 600;
    text-align: start;
  }

  &__valuation {
    line-height: 1;
    color: $neutral-400;
    font-size: 14px;
    text-align: start;

    span {
      color: $neutral-400;
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__semaforo {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;

    div {
      height: 36px;
      width: 36px;
      border-radius: 50%;
    }

    &__red {
      background-color: rgb(205, 98, 98);
      border: 2px solid rgb(205, 98, 98);
    }

    &__yellow {
      background-color: rgb(245, 175, 69);
      border: 2px solid rgb(245, 175, 69);
    }

    &__green {
      background-color: rgb(100, 194, 98);
      border: 2px solid rgb(100, 194, 98);
    }

    .inactive {
      background-color: transparent;
    }
  }

  &__ctas {
    width: 100%;
    display: flex;
    gap: 5px;
  }
}

.delete {
  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em $error !important;
  }
}

.continue {
  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em $accent-800 !important;
  }
}

.copy {
  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em $neutral-500 !important;
  }
}
