tspan {
  font-weight: 400 !important;
}

.bar {
  tspan {
    font-size: 10px;
  }
}

text {
  font-size: 13px !important;
}

.recharts-legend-item {
  svg {
    width: 12px !important;
  }

  .recharts-legend-item-text {
    font-size: 12px !important;
  }
}
