@import "../../../../styles/index.scss";

.form_button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-bakeca;
  border-radius: 4px;
  height: 50px;
  width: 100%;
  transition: background-color 0.25s;
  // transition: 0.25s;

  @media (max-width: 600px) {
    height: 60px;
  }

  // &:hover,
  // &:focus {
  //   box-shadow: 0 0.5em 0.5em -0.4em $primary-bakeca !important;
  //   transform: translateY(-0.15em) !important;
  // }

  // &:active {
  //   // border-color: $primary-bakeca !important;
  //   transform: translateY(0.15em) !important;
  // }

  &:disabled {
    pointer-events: none;
  }

  &__spans {
    display: flex;
    flex-direction: column;
    height: 50%;
    overflow: hidden;

    span {
      height: 100%;
      min-height: 100%;
      font-size: 14px;
      font-weight: 600;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      transition: transform 0.25s;

      img {
        height: 60%;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.success {
  background-color: $primary-bakeca;

  span {
    transform: translateY(-100%);
  }
}

.spinner {
  // border: 6px solid $gray;
  // border-top: 6px solid $primary-bakeca-dark;
  // border-radius: 50%;
  // width: 120px;
  // height: 120px;
  // animation: spin 2s linear infinite;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid $white;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
