.Toastify__toast {
  padding: 12px 15px;
  border-radius: 15px;
}

// .Toastify__toast-body {
//   padding-right: 18px;
// }

.progress-bar-success {
  background: #3b7a36;
}

.progress-bar-error {
  background: #b10000;
}

.progress-bar-warning {
  background: #002987;
}

.Toastify__toast-container {
  width: min-content;

  @media (max-width: 480px) {
    width: fit-content;
    margin: 0 auto;
  }
}
