@import "../../../../styles/index";

.area__services__wrapper {
  padding: 35px 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .header__text {
    text-align: center;
    margin-bottom: 40px;
  }

  .map_and_info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .map {
      position: relative;

      &__legend {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 10px 8px;
        border-radius: 5px;
        z-index: 1000;
        font-size: 13px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      span {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-color: $downloadSection;
        color: $white;
        padding: 5px;
        border-top-left-radius: 5px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .info {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 10px;

      span {
        font-size: 13px;
        text-transform: uppercase;
        font-family: $font-raleway;
        font-weight: 600;
        color: $neutral-primary;
      }

      &__section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }
    }
  }

  .services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .service {
      &_title {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-bottom: 10px;
        gap: 5px;

        img {
          width: 22px;
          margin-right: 5px;
        }
      }

      &_content {
        margin-left: 26px;
        display: flex;

        &_distance,
        &_name {
          display: flex;
          flex-direction: column;
          width: max-content;
          align-items: flex-end;

          p {
            font-size: 12px;
          }

          &_title {
            font-weight: 600;
            margin-bottom: 2px;
            text-align: start;
            width: 100%;
          }

          &_content {
            line-height: 14px;
          }
        }

        &_distance {
          margin-right: 4px;
        }

        &_name {
          align-items: flex-start;
          border-left: 1px solid #9d9d9d;
          padding-left: 4px;

          &_content {
            font-weight: 500;
          }
        }
      }
    }
  }
}
