@import "../../../styles";

.chart__wrapper {
  width: 100%;
  background-color: $white1;
  border-radius: 30px;
  padding: 35px 30px 20px;
  min-height: 450px;
  height: 100%;
  text-align: start;
}

.chart__source {
  margin-top: 15px;
  font-size: 10px;
  text-align: end;
}

.chart__container {
  @media (max-width: 750px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
