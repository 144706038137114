@import "../../../styles/index.scss";

.checkbox__wrapper {
  width: fit-content;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  position: relative;
  gap: 25px;

  span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.48px;
    font-family: $font-raleway;
  }

  .checkbox__field {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 9px;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid $bakeca-black;
    background-color: $white;
    appearance: none;
    -webkit-appearance: none;
    display: grid;
    place-content: center;

    // @media (max-width: 600px) {
    //   width: 36px;
    //   height: 36px;
    // }

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $bakeca-black;

      // @media (max-width: 600px) {
      //   width: 20px;
      //   height: 20px;
      // }
    }

    &:checked {
      &:before {
        transform: scale(1);
      }
    }
  }
}

// .checkbox__wrapper {
//   width: fit-content;
//   display: flex;
//   display: -webkit-flex;
//   align-items: center;
//   -webkit-align-items: center;
//   padding: 14px 10px;
//   position: relative;

//   span {
//     font-size: 15px;

//     a {
//       font-family: $font-raleway;
//       color: $bakeca-black;
//       font-size: 13px;
//       font-weight: 600;
//       letter-spacing: 0.54px;

//       transition: color 0.3s ease-in-out 0s;

//       &:hover,
//       &:focus {
//         color: $primary;
//       }
//     }
//   }

//   .checkbox__field {
//     position: relative;
//     width: 20px;
//     height: 20px;
//     padding: 0;
//     //border: none;
//     cursor: pointer;
//     margin-right: 10px;

//     &,
//     &:checked {
//       &::after {
//         content: "\2713";
//         position: absolute;
//         top: -2px;
//         left: -2px;
//         width: 20px;
//         height: 20px;
//         border-radius: 3px;
//         display: flex;
//         display: -webkit-flex;
//         align-items: center;
//         -webkit-align-items: center;
//         justify-content: center;
//         -webkit-justify-content: center;
//         font-size: 22px;
//       }
//     }

//     &::after {
//       border: 2px solid $bakeca-black;
//       background-color: #ffff;
//       color: $bakeca-black;
//       content: "";
//     }

//     &:disabled {
//       background-color: #aeaeb2;
//       // background-color: #ebebe4;
//     }
//   }
// }

.error {
  display: flex;
  display: -webkit-flex;
  color: $error;
  font-size: 13px;
  line-height: 1.2;
  margin-top: 8px;
  font-weight: 600;
  gap: 5px;
  font-family: $font-raleway;
  text-align: start;
  gap: 10px;
  align-items: center;
}
