@import "../../../styles/index.scss";

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  backdrop-filter: blur(35px);
  background: rgba(3, 67, 73, 0.25);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  filter: blur(0);

  .modal_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $bakeca-bg;
    width: 500px;
    max-width: 1200px;
    box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
    border-radius: 8px;

    @media (max-width: 520px) {
      width: 400px;
    }

    @media (max-width: 430px) {
      width: 350px;
      // height: 100% !important;
      // border-radius: 0px;
    }

    .modal_content {
      width: 100%;
      height: 100%;
    }
  }
}

.show {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.25s ease-in-out;
}
