@import "../../../../../../styles/index.scss";

.omi {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 7px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    background: linear-gradient(
      to top right,
      transparent calc(50% - 1px),
      $bakeca-black,
      transparent calc(50% + 1px)
    );
  }

  &__max,
  &__min {
    div {
      font-weight: 600;
      line-height: 1.35;
      letter-spacing: 0.7px;
      color: $bakeca-black;
    }

    div:first-child {
      font-size: 20px;
    }

    div:nth-child(2) {
      font-size: 14px;
    }
  }

  &__max {
    align-self: flex-end;
  }

  &__min {
    align-self: flex-start;
  }
}
