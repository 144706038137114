@import "../../../styles/index.scss";

.hero {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0px 0px 80px;

  @media (max-width: 1100px) {
    margin-top: 90px;
  }

  @media (min-width: 1700px) {
    padding: 30px 0px 0px 120px;
  }

  @media (max-width: 1100px) {
    padding: 60px 80px 0px;
  }

  @media (max-width: 600px) {
    padding: 60px 40px 0px;
  }

  @media (max-width: 450px) {
    padding: 60px 30px 0px;
  }

  &_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;

    @media (max-width: 1100px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;
    }
  }

  &__url_btn {
    color: $bakeca-black;
    font-size: 13px;
    transition: color 0.3s ease-in-out 0s;
    font-weight: 600;
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: flex-start;

    &:hover,
    &:focus {
      color: $primary-bakeca-dark;
    }

    svg {
      width: 13px;
      height: 13px;
      margin-right: 2px;
    }
  }
}

.isNotFromBakeca {
  @media (min-width: 1101px) {
    margin-top: 140px;
  }
}
