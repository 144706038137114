@import "../../../../../styles/index";
.omi__data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.omi__data__main {
  display: grid;
  display: -webkit-grid;
  grid-template-columns: 0.5fr 1fr;
  -webkit-grid-template-columns: 0.5fr 1fr;
}

.left__side {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 30px 0 0 30px;
  padding: 20px;
  background-color: $sea-green-middle;

  .item {
    text-align: center;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: $white;
    }

    .text {
      font-size: 15px;
      font-weight: 600;
      color: $white;
      margin-top: 2px;
    }
  }
}

.right__side {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  border-radius: 0 30px 30px 0;
  padding: 25px;
  background-color: $sea-green;

  .header_text {
    color: $light-gray;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
  }

  .prices__container {
    display: flex;
    align-items: center;
    // gap: 86px;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;

    .price__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      background-color: $sea-green-middle;
      padding: 20px 35px;
      border-radius: 10px;

      &:first-child {
        @media (min-width: 461px) {
          margin-right: -20px;
        }
      }

      &:last-child {
        @media (min-width: 461px) {
          margin-left: -20px;
        }
      }

      .subheader_text {
        color: $light-gray;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        padding-bottom: 5px;
        border-bottom: 1px solid white;
      }

      .text {
        color: $light-gray;
        font-size: 0.6rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 2px;
      }

      .price {
        color: $white;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;

        .euro {
          color: inherit;
          font-size: 0.9rem;
          font-weight: inherit;
          text-align: inherit;
        }
      }
    }

    .avg__container {
      background-color: $green;
      padding: 25px 35px;
      gap: 5px;
      z-index: 2;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

      .subheader_text {
        font-size: 14px;
        font-weight: 500;
      }

      .text {
        font-size: 0.7rem;
        font-weight: 500;
      }

      .price {
        font-size: 18px;
        font-weight: 700;

        .euro {
          font-size: 1rem;
        }
      }
    }
  }

  .photo__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 20px;

    .slider_rail {
      width: 100%;
    }

    .slider_track {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      align-items: center;

      &_track {
        width: 100%;
        height: 21px;
      }
    }

    .slider_home,
    .slider_rent,
    .slider_home_rail {
      position: absolute;
      height: 36px;
      width: 36px;
    }

    .slider_left_thumb {
      position: absolute;
      left: 0;
      height: 30px;
    }

    .slider_right_thumb {
      position: absolute;
      right: 0;
      height: 30px;
    }
  }
}

.pdf_sup {
  font-size: 0.7rem;
}
