.paypal_btn {
  display: flex;
  justify-content: center;
  height: 41px;
  width: 100%;

  .btn {
    height: 35px;
    width: 100%;
  }
}
