@import "../../../styles/index.scss";

.HOC {
  background-color: $neutral-200;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: 15px;
  padding: 15px;

  @media (max-width: 1200px) {
    display: block;
    background: $primary-gradient-opposite;
    padding: 40px;
  }

  @media (max-width: 500px) {
    padding: 40px 20px;
  }

  @media (max-width: 420px) {
    padding: 40px 0px;
  }

  &__left {
    grid-column: 1 / 10;
    height: 100%;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 60px;
    }

    &__logo {
      width: 300px;
      height: 100px;
      background: no-repeat center/80% url("../../../assets/svgs/hp-logo.svg");
      margin: 10px auto;

      @media (max-width: 1200px) {
        background-image: url("../../../assets/svgs/hp-logo-white-bg.svg");
        margin: 0 auto;
      }
    }

    &__content {
      background-color: $white;
      border-radius: 20px;
      padding: 40px 60px;

      @media (min-height: 720px) {
        padding: 60px;
      }

      @media (max-width: 1200px) {
        width: 70%;
      }

      @media (max-width: 900px) {
        width: 80%;
      }

      @media (max-width: 750px) {
        width: 90%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      @media (max-width: 420px) {
        border-radius: 0;
      }
    }
  }

  &__right {
    grid-column: 10 / 21;
    background: $primary-gradient;
    border-radius: 20px;
    padding: 60px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 100vh;
    // max-height: 974px;

    @media (min-height: 720px) {
      min-height: 100vh;
    }

    @media (max-width: 1200px) {
      display: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 60px;
      height: 100%;
      max-height: 1000px;

      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    &__header {
      text-align: start;
      padding: 0px 120px;

      @media (max-width: 1260px) {
        padding: 0px 100px;
      }

      &__large {
        line-height: 125%; /* 37.5px */
        letter-spacing: 1.5px;
        color: $white;
        font-weight: 400;
        margin-bottom: 10px;
      }

      &__small {
        line-height: 150%; /* 22.5px */
        letter-spacing: 0.3px;
        color: $white;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 550px;
      padding: 0px 60px;
    }
  }
}
