@import "../../../../styles/index.scss";

.signup {
  position: relative;
  background-color: $bakeca-bg;
  max-width: 800px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.5s ease-out;
  padding: 20px 0;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  border-radius: 8px;
  max-height: 98vh;

  @media (max-width: 820px) {
    max-width: 96%;
  }

  &__form {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    column-gap: 10px;
    padding: 0 20px;
    overflow-y: auto;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &::-webkit-scrollbar {
      border-radius: 8px;
    }

    &__social {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding-top: 20px;
      height: 100%;
      width: 100%;
      grid-column: 1/8;

      @media (max-width: 600px) {
        padding-top: 0px;
      }

      &__btns {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;

        @media (max-width: 600px) {
          flex-direction: row;
        }

        &__btn {
          height: 40px;
          width: 100%;
          max-width: 265px;
          border: 1px solid $bakeca-black;
          letter-spacing: 0.72px;
          color: $bakeca-black;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 12px;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          cursor: pointer;
          transition: 0.25s;

          @media (max-width: 600px) {
            height: 56px;
          }

          &:hover,
          &:focus {
            box-shadow: 0 0.5em 0.5em -0.4em rgba(0, 0, 0, 0.15);
            transform: translateY(-0.15em);
          }

          &:active {
            transform: translateY(0.15em);
          }

          img {
            width: 18px;

            @media (max-width: 600px) {
              width: 32px;
            }
          }

          .facebook {
            width: 22px;

            @media (max-width: 600px) {
              width: 37px;
            }
          }
        }

        &__login {
          padding: 0 20px 0 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
          gap: 5px;

          @media (max-width: 600px) {
            display: none;
          }

          &__cta {
            color: $bakeca-black;
            letter-spacing: 0.3px;
            font-size: 14px;

            span {
              letter-spacing: inherit;
              font-size: inherit;
              font-weight: 600;
              cursor: pointer;
              color: $bakeca-black;
              // text-decoration: underline;
              transition: color 0.3s ease-in-out 0s;

              &:hover,
              &:focus {
                color: $primary-bakeca-dark;
              }
            }
          }
        }
      }
    }

    &__hr {
      background: rgba(97, 97, 97, 0.28);
      height: 1px;
      margin: 30px 0;
      position: relative;
      grid-column: 8/9;
      width: 100%;

      @media (min-width: 601px) {
        align-self: flex-end;
        width: 1px;
        height: 95%;
        padding: 0;
        margin: 0 auto;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(97, 97, 97, 0.43);
        background-color: $bakeca-bg;
        line-height: 115%; /* 18.4px */
        letter-spacing: 0.16px;
        font-weight: 500;
        font-size: 10px;
        padding: 0 6px;

        @media (min-width: 601px) {
          writing-mode: vertical-rl; /* or vertical-lr */
          text-orientation: upright; /* Ensures the text remains upright */
          padding: 6px 0;
        }
      }
    }

    &__formik {
      grid-column: 9/19;
      width: 100%;

      .form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-direction: column;

        &__field {
          width: 100%;
        }

        .privacy {
          a {
            font-size: 12px;
            font-weight: 600;
            color: $bakeca-black;
            text-decoration: underline;
            transition: color 0.3s ease-in-out 0s;

            &:hover,
            &:focus {
              color: $primary-bakeca-dark;
            }
          }
        }

        &__submit {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          margin-top: 5px;
        }
      }
    }

    &__login {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 601px) {
        display: none;
      }

      &__cta {
        color: $bakeca-black;
        letter-spacing: 0.3px;
        font-size: 14px;

        span {
          letter-spacing: inherit;
          font-size: inherit;
          font-weight: 600;
          cursor: pointer;
          color: $bakeca-black;
          transition: color 0.3s ease-in-out 0s;

          &:hover,
          &:focus {
            color: $primary-bakeca-dark;
          }
        }
      }
    }
  }
}
