@import "../../../../styles/index";

.first_page_wrapper {
  position: relative;
  height: 100%;
}

.first_page_img {
  height: 100%;
  background-image: url("../../../../assets/images/download-cover.webp");
  background-size: cover;
}

.first_page_header {
  height: 250px;
  width: 100%;
  background-color: $white1;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  z-index: 1000;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  &_title {
    font-size: 26px;
    font-weight: 600;
    color: $sea-green;
    text-transform: uppercase;
    font-family: $font-raleway;
  }

  &_address {
    font-size: 28px;
    font-weight: 400;
    color: $sea-green;
  }

  &_date {
    font-size: 14px;
    color: $pale-green;
  }
}
