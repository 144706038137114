@import "../../../styles/index.scss";

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;

  @media (max-width: 980px) {
    display: none;
  }

  thead {
    tr {
      th {
        font-weight: 600;
        color: $neutral-400;
        font-size: 12px;
        padding-bottom: 5px;
      }
    }
  }

  tbody {
    tr {
      background-color: $white;
      margin-bottom: 10px;
      // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

      td {
        font-weight: 600;
        color: $neutral-primary;
        font-size: 14px;
        padding: 10px 15px;
        box-shadow: 0px 1.95px 0px rgba(0, 0, 0, 0.15);
      }

      .table_img_container {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;

        .table_img {
          width: 80px;
          height: 60px;
          display: block;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .table_url {
        a {
          color: $neutral-400;
          font-size: 14px;
          transition: color 0.3s ease-in-out 0s;
          display: inline-block;
          font-weight: 600;

          &:hover,
          &:focus {
            color: $primary-bakeca-dark;
          }
        }
      }

      .table_icons_container {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 0px;

        .table_icons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;

          img {
            width: 18px;
            cursor: pointer;
            transition: 0.25s;

            &:hover,
            &:focus {
              transform: translateY(-0.15em);
            }

            &:active {
              transform: translateY(0.15em);
            }
          }
        }
      }

      .semaforo {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 100%;

        div {
          height: 18px;
          width: 18px;
          border-radius: 50%;
        }

        &__red {
          background-color: rgb(205, 98, 98);
          border: 2px solid rgb(205, 98, 98);
        }

        &__yellow {
          background-color: rgb(245, 175, 69);
          border: 2px solid rgb(245, 175, 69);
        }

        &__green {
          background-color: rgb(100, 194, 98);
          border: 2px solid rgb(100, 194, 98);
        }

        .inactive {
          background-color: transparent;
        }
      }
    }
  }
}
