@import "../../../../../styles/index";

.gender__distribution__area__wrapper {
  // padding: 30px 40px;
  position: relative;

  .gender__distribution__area__header {
    font-size: 24px;
    font-weight: 600;
    color: $sea-green;
  }

  .caption {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    margin-bottom: 10px;

    .type__color {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .male__color {
      background-color: $sea-green-light;
    }

    .female__color {
      background-color: $sea-green-middle;
    }
  }

  .circle__wrapper {
    margin-top: 126px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;

    @media (max-width: 1000px) {
      margin-top: 56px;
    }
  }

  .chart__source {
    position: absolute !important;
    bottom: 20px !important;
    right: 30px !important;
  }
}
