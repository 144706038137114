@import "../../../../styles/index.scss";

.auth__header {
  text-align: start;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  &__large {
    font-size: 30px;
    // text-transform: uppercase;
    line-height: 115%; /* 39.1px */
    letter-spacing: 1.02px;
    // margin-bottom: 10px;
    font-weight: 600;
    padding-right: 35px;
  }

  &__small {
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    font-size: 14px;
  }
}
