@import "../../../../styles/index";

.seventh_page {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__charts_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 10px;

    .general__card {
      background: $white1;
      // border: 2px solid red;
      border-radius: 30px;
      height: auto;
    }
  }
}
