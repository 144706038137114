@import "../../../../../../styles/index.scss";

.ads_amount {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $bakeca-black;
  border-radius: 10px;
  height: 75px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  div {
    // font-size: 26px;
    // font-weight: 700;
    // color: $neutral-900;

    font-weight: 600;
    line-height: 1.35;
    font-size: 20px;
    letter-spacing: 0.7px;
    color: $bakeca-black;
  }

  div:nth-child(2) {
    font-size: 14px;
  }
}
