@import "../../../../styles/index.scss";

.info__cards {
  padding: 120px 0px 20px;
  position: relative;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 100px 0px 40px;
  }

  @media (max-width: 550px) {
    padding: 100px 0px 40px;
  }

  &_header {
    line-height: 1.5;
    padding: 0 80px 40px;
    width: 100%;
    max-width: 1085px;
    margin: 0 auto;

    @media (max-width: 600px) {
      padding: 0 60px 40px;
    }

    @media (max-width: 550px) {
      padding: 0 40px 40px;
    }
  }

  &__sliders {
    background-color: $bakeca-gray;
    padding: 40px 80px;

    &__desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      justify-items: center;
      width: 100%;
      max-width: 1085px;
      margin: auto;
      gap: 20px;

      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 510px) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 1250px) {
        display: none;
      }
    }

    &__tablet {
      display: none;

      @media (min-width: 601px) and (max-width: 1250px) {
        /* Your styles for the element */
        display: block;
      }

      @media (max-width: 800px) {
        width: 450px;
        margin: 0 auto;
      }
    }

    &__mobile {
      @media (min-width: 601px) {
        display: none;
      }
    }
  }

  .card_title {
    font-weight: 600;
    color: $bakeca-black;
    font-size: 16px;

    sup {
      font-size: 8px;
    }
  }

  .cta {
    button {
      line-height: 2;
      border-radius: 15px;

      @media (max-width: 900px) {
        width: 100% !important;
      }
    }
  }
}

.isNotFromBakeca {
  padding: 100px 0px 20px;

  @media (max-width: 600px) {
    padding: 80px 0px 40px;
  }

  @media (max-width: 550px) {
    padding: 100px 0px 40px;
  }
}
