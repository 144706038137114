@import "../../../../../styles/index";

.common__card__wrapper {
  padding: 15px 25px 30px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  // justify-content: space-around;
  gap: 10px;
  height: 100px;
  // height: 100%;
  // min-height: 120px;

  @media (max-width: 1000px) {
    gap: 10px;
    min-height: 105px;
  }

  .first__row {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    gap: 10px;
    line-height: 1;

    .header {
      @media (max-width: 840px) {
        max-width: 160px;
      }
      // font-size: 14px;
      // font-weight: $font-weight-middle;
      // color: $gray2;
    }

    .icon__wrapper {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      border-radius: 5px;
      width: 25px;
      height: 25px;

      @media (min-width: 840px) and (max-width: 1050px) {
        display: none;
      }

      background-color: $sea-green-light;

      svg {
        width: 25px;
      }
    }
  }

  .content {
    font-size: 30px;
    font-weight: 700;
    color: $sea-green;
    margin-top: 10px;
    margin-bottom: 10px;
    // margin: 20px 0px 10px 0px;

    @media (min-width: 840px) and (max-width: 950px) {
      font-size: 26px;
    }

    sup {
      font-size: 15px;
    }
  }

  .chart__source {
    font-size: 10px;
    text-align: end;
    position: absolute;
    bottom: 10px;
    right: 30px;
  }
}
