@import "../../../../styles/index.scss";

.modal_content {
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 500px) {
    padding: 40px 20px 30px;
  }

  &__header {
    // text-transform: uppercase;
    font-family: $font-raleway;
    font-weight: 500;
    color: $neutral-primary;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;

    @media (max-width: 500px) {
      font-size: 20px;
      line-height: 1.4;
      text-align: center;
    }
  }

  &__ctas {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .close_btn {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    color: $white;
    cursor: pointer;
    color: $neutral-primary;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .delete_acct {
    &:hover,
    &:focus {
      box-shadow: 0 0.5em 0.5em -0.4em $error !important;
    }
  }
}
