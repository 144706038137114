@import "../../../../styles/index";

.gauge__wrapper {
  width: 100%;
  background-color: $white1;
  border-radius: 30px;
  padding: 40px 30px 20px;
  height: 100%;

  @media (max-width: 1000px) {
    min-height: 450px;
  }
}

.chart__wrapper {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  // margin-top: 110px;
  position: relative;
}

.medium {
  text-transform: uppercase;

  font-size: 36px;
  position: absolute;
  top: 48%;
  font-weight: 600;

  span {
    font-size: 36px;
    position: absolute;
    top: 50%;
    font-weight: 600;
  }
}

.caption {
  font-size: 13px;
  // font-weight: 600;
  color: #9d9d9d;
  position: absolute;
  top: 78%;
  text-align: center;
  line-height: 1.3;

  b {
    font-size: 15px;
  }

  span {
    font-size: inherit;
    font-weight: inherit;
  }
}

.gauge_margin_top {
  @media (min-width: 1000px) {
    margin-top: 80px;
  }
}

.source {
  position: absolute !important;
  bottom: 10px !important;
  right: 24px !important;
}
