@import "../../../../styles/index.scss";

.buy_modal {
  position: relative;
  background-color: $bakeca-bg;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  border-radius: 8px;
  max-height: 98vh;

  @media (max-width: 820px) {
    max-width: 96%;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    gap: 10px;
    overflow-y: auto;

    @media (min-height: 1001px) {
      gap: 20px;
    }

    &::-webkit-scrollbar {
      border-radius: 8px;
    }

    &__hr {
      background: rgba(97, 97, 97, 0.28);
      height: 1px;
      margin: 10px 0;
      position: relative;
      width: 100%;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(97, 97, 97, 0.43);
        background-color: $bakeca-bg;
        line-height: 115%; /* 18.4px */
        letter-spacing: 0.16px;
        font-weight: 500;
        font-size: 10px;
        padding: 0 6px;
      }
    }

    &__subtitle {
      font-weight: 600;
      letter-spacing: 0.3px;
      font-size: 18px;
      text-align: start;
      width: 100%;
    }
  }
}
