@import "../../../../styles/index.scss";

.login {
  position: relative;
  background-color: $bakeca-bg;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  border-radius: 8px;
  max-height: 98vh;

  @media (max-width: 820px) {
    max-width: 96%;
  }

  &__form {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    column-gap: 10px;
    padding: 0 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      border-radius: 8px;
    }

    &__social {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      height: 100px;
      justify-content: flex-end;

      @media (max-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
      }

      &__btn {
        height: 40px;
        width: 100%;
        border: 1px solid $bakeca-black;
        letter-spacing: 0.72px;
        color: $bakeca-black;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        cursor: pointer;
        transition: 0.25s;

        @media (max-width: 600px) {
          height: 56px;
        }

        &:hover,
        &:focus {
          box-shadow: 0 0.5em 0.5em -0.4em rgba(0, 0, 0, 0.15);
          transform: translateY(-0.15em);
        }

        &:active {
          transform: translateY(0.15em);
        }

        img {
          width: 18px;

          @media (max-width: 600px) {
            width: 32px;
          }
        }

        .facebook {
          width: 22px;

          @media (max-width: 600px) {
            width: 37px;
          }
        }
      }
    }

    &__hr {
      background: rgba(97, 97, 97, 0.28);
      height: 1px;
      margin: 20px 0;
      position: relative;
      width: 100%;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(97, 97, 97, 0.43);
        background-color: $bakeca-bg;
        line-height: 115%; /* 18.4px */
        letter-spacing: 0.16px;
        font-weight: 500;
        font-size: 10px;
        padding: 0 6px;
      }
    }

    &__formik {
      grid-column: 9/19;
      width: 100%;

      .form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-direction: column;

        &__field {
          width: 100%;
        }

        &__submit {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          margin-top: 5px;
        }
      }
    }

    &__login {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &__cta {
        color: $bakeca-black;
        letter-spacing: 0.3px;
        font-size: 14px;

        span {
          letter-spacing: inherit;
          font-size: inherit;
          font-weight: 600;
          cursor: pointer;
          color: $bakeca-black;
          transition: color 0.3s ease-in-out 0s;

          &:hover,
          &:focus {
            color: $primary-bakeca-dark;
          }
        }
      }
    }
  }
}
