@import "../../../../styles/index.scss";

.hero_stats {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
  width: 100%;
  justify-content: center;
  max-width: 1000px;
  margin: 110px auto 0;
  padding: 0px 80px;

  @media (max-width: 600px) {
    padding: 0px 40px;
  }

  @media (max-width: 550px) {
    gap: 80px;
  }

  @media (max-width: 450px) {
    padding: 0px 30px;
  }

  &_stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    // background-color: $primary-bakeca-dark;
    // background-color: #93c947;
    // border-radius: 4px;
    border-left: 3px solid $primary-bakeca-dark;
    width: fit-content;
    height: 60px;
    align-items: flex-start;
    padding-left: 32px;

    @media (max-width: 550px) {
      width: 250px;
    }

    * {
      color: $bakeca-black;
    }

    h3 {
      font-weight: 700;
      font-size: 40px;
    }

    p {
      font-size: 16px;
      letter-spacing: 0.7px;
      font-weight: 500;
    }
  }
}
