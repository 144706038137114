@import "../../../../styles";

.custom_tooltip {
    border-radius: 10px;
    background-color: $white;
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
    &__content {
      margin-top: 10px;
  
      span {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -1.5px;
      }
    }
  }