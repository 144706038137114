@import "../../../styles/index.scss";

.town__info {
  position: relative;

  // &_gradient {
  //   position: absolute;
  //   bottom: 0;
  //   background: $primary-bakeca-gradient;
  //   width: 100%;
  //   // height: 76%;
  //   height: 66%;
  //   z-index: 0;

  //   @media (max-width: 1000px) {
  //     height: 76%;
  //   }

  //   @media (max-width: 600px) {
  //     height: 46%;
  //   }
  // }
}
