@import "../../../../styles/index";

.page__container {
  padding: 60px 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 40px;

  .subsection {
    p:first-child {
      margin-bottom: 20px;
    }
  }
}
