@import "../../../../styles/index";

.fifth_page {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  gap: 20px;

  &__energy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &__content {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      align-items: center;
      gap: 10px;

      .chart {
        grid-column: 1/6;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
      }

      .chart_description {
        grid-column: 6/10;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        height: 100%;
      }
    }
  }
}
