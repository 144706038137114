@import "../../styles/index.scss";

.dashboard {
  .swiper {
    margin: 0;
    height: 430px;

    // @media (max-width: 430px) {
    //   height: 420px;
    // }
  }

  .swiper-slide {
    @media (min-width: 431px) {
      width: 250px !important;
    }
  }

  .swiper-pagination-bullet {
    background: $primary-bakeca-dark;
    opacity: 0.5;
  }
}
