@import "../../../../styles/index";

.second_page {
  padding: 40px 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  // justify-content: space-between;
  height: calc(100% - 75px);
  gap: 40px;

  &__evaluation {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;

    .title {
      text-align: start;
    }

    .stimas {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0 auto;

      &__stima {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .text_prediction {
            line-height: 1.1;
            font-weight: 600;
            font-size: 18px;
            color: $primary;
          }

          .prediction_mask {
            border-radius: 10px;
            z-index: 1;
          }

          .text {
            // line-height: 1.4;
            font-weight: 500;
            font-size: 13px;
            color: $primary;
          }

          .min,
          .max {
            font-size: 24px;
            font-weight: 400;
            padding: 20px 0px;
            // border: 2px solid $primary;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            border-radius: 10px;
            color: $primary;
            width: 200px;

            span {
              color: inherit !important;
              font-size: 16px;
            }
          }

          .min {
            margin-right: -10px;
          }

          .max {
            margin-left: -10px;
          }
        }
      }
    }

    .semaforo {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      padding: 20px;
      border-radius: 10px;
      width: 660px;
      margin: 0 auto;

      &__lights {
        grid-column: 1/3;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;

        div {
          height: 25px;
          width: 25px;
          border-radius: 50%;
        }

        &__red {
          background-color: rgb(205, 98, 98);
        }

        &__yellow {
          background-color: rgb(245, 175, 69);
        }

        &__green {
          background-color: rgb(100, 194, 98);
        }

        .inactive {
          background-color: $white1;
        }
      }

      &__whysemaforo {
        grid-column: 3/11;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        gap: 5px;

        &__text {
          text-align: start !important;
          font-size: 14px !important;
        }

        &__info {
          text-align: start !important;
          font-weight: 600 !important;
          font-size: 14px !important;
        }
      }
    }

    .text_summary {
      width: 660px;
      margin: 0 auto;

      div {
        text-align: justify !important;
      }
    }
  }

  .prediction {
    font-weight: 600;
    font-size: 42px;
    padding: 20px 0px;
    background: $primary;
    color: white;
    // border: 2px solid $neutral-primary;
    border-radius: 10px;
    width: 280px;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

    span {
      color: inherit !important;
      font-size: 28px;
    }

    &__mask {
      width: 100%;
      height: 100%;
    }
  }

  &__ad_price {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    width: 100%;

    .prices {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin: 0 auto;
    }
  }

  &__comparation {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    width: 100%;

    .formulas {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin: 0 auto;

      &__formula {
        background-color: red;
        font-weight: 600;
        font-size: 28px;
        padding: 20px 0px;
        color: $neutral-primary;
        border-radius: 10px;
        width: 220px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      }
    }
  }
}
