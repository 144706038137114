@import "../../../styles/index.scss";

.why {
  padding: 120px 80px 80px 80px;

  @media (max-width: 1200px) {
    padding: 120px 80px 80px 80px;
  }

  @media (max-width: 540px) {
    padding: 80px 60px 40px 60px;
  }

  @media (max-width: 500px) {
    padding: 80px 40px 20px 40px;
  }

  @media (max-width: 460px) {
    padding: 80px 40px 0px 40px;
  }

  &__header {
    margin-bottom: 60px;
    width: 100%;
    max-width: 1085px;
    margin: 0 auto 60px;

    @media (max-width: 900px) {
      margin-bottom: 40px;
    }

    // span {
    //   display: inline-block;
    //   background: $houseplus-gradient;
    //   background-clip: text;
    //   -webkit-background-clip: text; /* For Webkit-based browsers */
    //   color: transparent; /* Hide the original text color */
    // }
  }

  &__desktop {
    width: 100%;
    max-width: 1085px;
    margin: 0 auto;

    @media (max-width: 1070px) {
      width: 840px;
    }

    @media (max-width: 1000px) {
      display: none;
    }

    &__btns {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 40px;
      height: 60px;
      background-color: $bakeca-gray;
      border-radius: 28px;
      position: relative;

      @media (max-width: 1100px) {
        height: 60px;
      }

      &_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 28px;
        width: calc(1085px / 5);
        height: 100%;
        padding: 0px 10px;
        background-color: transparent;
        color: $bakeca-black;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
        transition: 0.25s;

        @media (max-width: 1070px) {
          width: calc(840px / 5);
        }
      }

      .active_btn {
        background-color: $primary-bakeca-dark;
        color: $white;
      }
    }

    &__cards_wrapper {
      overflow: hidden;
      max-width: 1150px;
      margin: 0 auto;
      display: flex;
    }

    &__cards {
      display: flex;
      transition: transform 0.5s ease-in-out;
      width: 100%;

      .why__card {
        width: 100%;
        min-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: flex-end;
        height: 380px;
        opacity: 0;
        transition: 0.25s;
        gap: 20px;

        &_text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 20px;

          &_header {
            font-family: $font-raleway;
            font-size: 36px;
            // text-transform: uppercase;
            // letter-spacing: 1.02px;
            font-weight: 600;
            text-align: left;
          }

          &_p {
            text-align: left;
          }
        }

        img {
          width: 100%;
          height: 100%;
          // max-width: 380px;
        }
      }

      .active {
        opacity: 1;
        transition: 0.5s ease-out 0.05s;
      }
    }
  }
}
