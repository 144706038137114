@import "../../../../styles/index.scss";

.mobile__slider {
  @media (min-width: 1001px) {
    display: none;
  }

  &_card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    height: 380px;

    @media (max-width: 1000px) {
      flex-direction: column;
      height: auto;
      padding-top: 100px;
      gap: 0px;
      justify-content: flex-start;
      height: 730px;
    }

    @media (max-width: 780px) {
      height: 680px;
    }

    @media (max-width: 680px) {
      height: 630px;
    }

    @media (max-width: 420px) {
      height: 600px;
    }

    &_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      &_header {
        font-family: $font-raleway;
        // text-transform: uppercase;
        font-size: 36px;
        letter-spacing: 1.02px;
        font-weight: 600;
        text-align: center;
      }

      &_p {
        font-size: 15px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        text-align: center;
      }
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 705px;

      @media (max-width: 750px) {
        height: 70vw;
      }
    }
  }
}
