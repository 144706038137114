.HOC__left__list {
  padding: 0px 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 750px;

  @media (min-width: 1201px) {
    display: none;
  }

  @media (max-width: 750px) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    justify-items: center;
  }

  @media (max-width: 500px) {
    column-gap: 30px;
    padding: 0px 40px;
  }

  @media (max-width: 420px) {
    column-gap: 20px;
    padding: 0px 20px;
  }

  @media (max-width: 401px) {
    column-gap: 15px;
  }

  img {
    width: 7vw;
    min-width: 100px;

    @media (max-width: 500px) {
      min-width: 90px;
    }

    @media (max-width: 450px) {
      min-width: 80px;
    }
  }

  .first_img {
    @media (max-width: 750px) {
      grid-column: 1 / 3;
    }
  }
  .second_img {
    @media (max-width: 750px) {
      grid-column: 3 / 5;
    }
  }
  .third_img {
    @media (max-width: 750px) {
      grid-row: 2 / 3;
      grid-column: 4 / 6;
    }
  }
  .fourth_img {
    @media (max-width: 750px) {
      grid-column: 2 / 4;
    }
  }
  .fifth_img {
    @media (max-width: 750px) {
      grid-row: 1 / 2;
      grid-column: 5 / 7;
    }
  }
}

.HOC__right__list {
  padding: 0px 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 780px;

  img {
    width: 7vw;
    max-width: 100px;
  }
}
