.circle__bar__wrapper {
  position: relative;

  .icon__wrapper {
    position: relative;

    img {
      position: absolute;
      bottom: 90px;
      left: 85px;
    }
  }

  .circle__bar__labels {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
