@import "../../../../styles/index.scss";
.motion_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  @media (min-width: 1101px) {
    display: none;
  }

  .dashboard_btn {
    button {
      &:hover,
      &:focus {
        box-shadow: 0 0.5em 0.5em -0.4em #ffffff !important;
      }
    }

    .dashboard_button {
      svg {
        width: auto !important;
        height: auto !important;
      }
    }
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $primary-bakeca;
  }

  ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style-type: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: max-content;

    @media (max-width: 465px) {
      width: 90%;
    }

    li {
      text-decoration: none;
      color: $bakeca-bg;
      text-transform: uppercase;
      font-size: 28px;
      font-family: $font-raleway;
      font-weight: 600;
      letter-spacing: 1.5px;
      cursor: pointer;

      &:focus {
        outline: 1px solid $white;
        padding: 5px;
        border-radius: 2px;
      }
    }
  }
}

// .sidebar {
//   //   height: calc(100vh - 90px);
//   //   margin-top: 90px;
//   height: 100vh;
//   overflow: hidden;
//   position: fixed;
//   width: 100%;
//   z-index: 1000;
//   visibility: hidden;
//   transition: visibility;
//   transition-delay: 1.5s;

//   &_top {
//     height: 50%;
//     position: absolute;
//     top: -50%;
//     width: 100%;
//     background: $primary-bakeca;
//     transition: top 0.7s cubic-bezier(1, 0, 0.62, 1) 0.6s;
//   }

//   &_bottom {
//     height: 50%;
//     position: absolute;
//     bottom: -50%;
//     width: 100%;
//     background: $primary-bakeca;
//     transition: bottom 0.7s cubic-bezier(1, 0, 0.62, 1) 0.3s;
//   }

//   &_content {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     list-style-type: none;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     gap: 30px;
//     align-items: center;
//     width: max-content;

//     $translate-increment: 20px;
//     $translate-delay: 0.08s;

//     li {
//       opacity: 0;

//       @for $i from 1 through 6 {
//         &:nth-child(#{$i}) {
//           transform: translateX($translate-increment * $i);
//           transition: opacity 0.5s ease-in $translate-delay / $i,
//             transform 0.5s ease-in $translate-delay / $i;
//         }
//       }
//     }

//     a {
//       text-decoration: none;
//       color: $neutral-200;
//       text-transform: uppercase;
//       font-size: 28px;
//       font-family: $font-raleway;
//       font-weight: 600;
//       letter-spacing: 1.02px;
//     }

//     .demo {
//       width: 100%;
//     }
//   }
// }

// .opened_sidebar {
//   visibility: visible;
//   transition: visibility;
//   transition-delay: 0s;

//   .sidebar_top {
//     top: 0;
//     transition: top 0.7s cubic-bezier(1, 0, 0.62, 1) 0s;
//   }

//   .sidebar_bottom {
//     bottom: 0;
//     transition: bottom 0.7s cubic-bezier(1, 0, 0.62, 1) 0.2s;
//   }

//   .sidebar_content {
//     li {
//       opacity: 1;

//       $translate-increment: 20px;
//       $translate-delay: 0.08s;

//       @for $i from 1 through 6 {
//         &:nth-child(#{$i}) {
//           transform: translateX(0);
//           transition: opacity 0.5s ease-in-out 0.5 + $translate-delay * $i,
//             transform 0.5s ease-in-out 0.5 + $translate-delay * $i;
//         }
//       }
//     }
//   }
// }
