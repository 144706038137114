.motion_burger {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  pointer-events: auto !important;
}
