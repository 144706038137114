.hero_real_images {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 90%;
  justify-self: flex-end;

  @media (max-width: 1100px) {
    justify-content: center;
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: cover;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    max-height: 350px;

    @media (min-width: 1101px) {
      max-width: 660px;
    }

    @media (min-width: 1501px) {
      max-width: 750px;
    }

    @media (max-width: 1100px) {
      border-radius: 10px;
      width: 100%;
    }
  }
}

.hero_placeholder_images {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;

  @media (max-width: 1100px) {
    justify-content: center;
    width: 100%;
  }

  &_main {
    width: 100%;
    object-fit: cover;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    @media (min-width: 1001px) {
      max-width: 660px;
    }

    @media (min-width: 1501px) {
      max-width: 750px;
    }

    @media (max-width: 1100px) {
      border-radius: 10px;
      width: 100%;
    }
  }
}
