html,
body,
#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
}

input {
  border-radius: 4px;
}

#root {
  background-position: right bottom, left top;
  background-repeat: no-repeat;
  justify-content: flex-start;
}

table,
tbody,
thead,
tfoot,
th,
tr,
td {
  font-weight: unset;
}

select,
select:focus-visible,
button {
  outline: none;
  cursor: pointer;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffff inset !important;
}

input:-webkit-autofill::first-line {
  font-size: 18px !important;
  color: $neutral-primary;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #0f172a40;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $primary-bakeca;
  -webkit-box-shadow: 0 0 1px $primary-bakeca;
}

:focus {
  outline: none;
}
