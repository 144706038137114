@import "../../../styles/index.scss";

.footer__container {
  padding: 80px;
  position: relative;
  background-color: $white;
  border-top: 1px solid $neutral-primary;

  @media (max-width: 1200px) {
    padding: 140px 80px 80px;
  }

  @media (max-width: 800px) {
    padding: 120px 80px;
  }

  @media (max-width: 540px) {
    padding: 120px 60px;
  }

  @media (max-width: 500px) {
    padding: 120px 40px;
  }

  .btt {
    position: absolute;
    bottom: 40px;
    right: 40px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1085px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 960px) {
      gap: 60px;
    }

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 100px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      @media (max-width: 800px) {
        align-items: center;
      }

      &__logo {
        width: 280px;
      }

      &__name,
      &__iva {
        color: $neutral-400;
        line-height: 150%; /* 27px */
        letter-spacing: 0.36px;
        font-size: 15px;
        // margin-left: 10px;
        text-align: start;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      &__social {
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;

        a {
          width: 30px;
          height: 30px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .youtube {
          padding-top: 9px;
          height: 42px;
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      list-style-type: none;

      @media (min-width: 701px) and (max-width: 1200px) {
        position: absolute;
        flex-direction: row;
        top: -80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: 800px) {
        align-items: center;
      }

      li {
        a {
          font-size: 13px;
          cursor: pointer;
          color: $neutral-400;
          transition: color 0.3s ease-in-out 0s;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
      width: 260px;

      @media (max-width: 800px) {
        gap: 30px;
        width: 280px;
      }

      &__text {
        text-align: start;
        font-weight: 600;
        line-height: 125%; /* 25px */
        color: $neutral-primary;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      &__contact_us {
        width: 100%;

        button {
          @media (max-width: 800px) {
            height: 60px !important;
          }

          &:hover {
            box-shadow: 0 0.5em 0.5em -0.4em $primary;
          }

          &:active {
            box-shadow: none;
          }
        }
      }

      &__contact {
        background: transparent;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        border: 1px solid $primary;
        font-size: 14px;
        font-weight: 600;
        color: $primary;
        text-decoration: none;

        transition: 0.25s;

        &:hover {
          box-shadow: 0 0.5em 0.5em -0.4em $primary;
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }

  .btt__button {
    &:hover {
      box-shadow: 0 0.5em 0.5em -0.4em $accent-800;
    }

    &:active {
      box-shadow: none;
    }
  }
}
