@import "../../../../styles/index.scss";

.hero_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  @media (min-width: 1101px) {
    padding-right: 46px;
    gap: 32px;
  }

  @media (min-width: 1600px) {
    gap: 50px;
  }

  &_header {
    text-align: left;
    margin-bottom: 10px;

    span {
      display: inline-block;
      // background: $bakeca-gradient;
      // background-clip: text;
      // -webkit-background-clip: text; /* For Webkit-based browsers */
      // color: transparent; /* Hide the original text color */
      color: $primary-bakeca-dark;
    }
  }

  &_subheader {
    text-align: left;

    .fake_btn {
      background-color: $primary;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      padding: 0.4rem 0.7rem !important;
      font-size: 13px !important;
      border-radius: 0.25rem;
      white-space: normal;
      border-width: 1px;
      display: inline-block;
      letter-spacing: 0.5px;
      line-height: 1.25;
    }
  }

  .hero_text_ctas {
    margin-top: 10px;

    &__btns {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      margin-top: -10px;

      @media (max-width: 570px) {
        flex-direction: column-reverse;
        align-items: flex-start;

        .buy_btn {
          button {
            height: 80px !important;
          }
        }
      }

      .buy_btn {
        position: relative;

        &:hover,
        &:focus {
          .buy_btn__tooltip {
            transform: translate(-50%, -0.2em) !important;
          }
        }

        &:active {
          .buy_btn__tooltip {
            transform: translate(-50%, 0.2em) !important;
          }
        }

        &__tooltip {
          position: absolute;
          top: -24px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $bakeca-black;
          border: 1px solid #ffffff;
          color: #ffffff;
          padding: 10px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
          transition: 0.25s;
          pointer-events: none;
        }
      }

      button {
        span {
          font-size: 16px !important;
        }
      }
    }
  }
}
