@import "../../../../styles/index.scss";

.forgot_pwd {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.5s ease-out;
  padding: 20px 0;
  background-color: $bakeca-bg;
  width: 500px;
  max-width: 1200px;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  border-radius: 8px;
  max-height: 98vh;

  @media (max-width: 820px) {
    max-width: 96%;
  }
}

.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // gap: 20px;
  padding: 10px 20px 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 8px;
  }

  &__submit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    &__text {
      color: $neutral-400;
      letter-spacing: 0.4px;
      font-size: 14px;

      span {
        letter-spacing: inherit;
        font-size: inherit;
        font-weight: 600;
        cursor: pointer;
        color: $bakeca-black;
        transition: color 0.3s ease-in-out 0s;

        &:hover,
        &:focus {
          color: $primary-bakeca-dark;
        }
      }
    }
  }
}
