.close_btn {
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;
  z-index: 100;

  svg {
    width: 20px;
    height: 20px;
  }
}
