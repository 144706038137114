@import "../../styles/index.scss";

.custom_toast {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    &__text {
      font-family: $font-raleway;
      font-weight: 600;
      line-height: 115%; /* 29.9px */
      letter-spacing: 0.78px;
      // text-transform: uppercase;
      text-align: start;
      word-break: normal;
    }

    &__icon {
      width: 50px;
    }
  }

  &__subheader {
    text-align: start;
    font-size: 13px;
    line-height: 150%; /* 27px */
    letter-spacing: 0.36px;
  }
}
