@import "../../../styles/index.scss";

.loader {
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1001;
  background-color: rgba(236, 247, 248, 0.9);
  // background-color: rgba(255, 255, 255, 0.7);
  position: fixed;

  .spinner__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      // border: 6px solid $gray;
      // border-top: 6px solid $primary-dark;
      // border-radius: 50%;
      // width: 120px;
      // height: 120px;
      // animation: spin 2s linear infinite;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: inline-block;
      border-top: 3px solid $primary-500;
      border-right: 3px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    .spinner__inner {
      position: absolute;
      top: 49%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      height: 47px;

      .spinner__icon {
        // animation: pulse 1s ease-in infinite;
        width: 100%;
        height: 100%;
      }
    }
  }

  // @keyframes spin {
  //   0% {
  //     transform: rotate(0deg);
  //   }

  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
