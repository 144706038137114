@import "../../../../../../styles/index.scss";

.label__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 3px 10px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  width: 60px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid $primary;

  .label__text {
    // color: #436e76;
    // font-size: 12px;
    // font-weight: 500;
    // margin: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.7px;
    color: $neutral-primary;
  }

  .label__value {
    // color: $gray2;
    // font-size: 15px;
    // font-weight: 500;
    // margin: auto;
    font-size: 12px;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.7px;
    color: $neutral-primary;
  }
}
